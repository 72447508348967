<template>
  <div class="stand-up-summary card border m-rounded-10 p-2 p-md-3">
    <b-container fluid>
      <b-row class="m-min-h-100">
        <b-col
          class="border-right d-flex align-items-center justify-content-center"
          cols="5"
          md="3"
          lg="2"
        >
          <strong>{{ filterText[from] }}</strong>
        </b-col>
        <b-col
          cols="7"
          md="9"
          lg="10"
          class="d-flex flex-column justify-content-center pl-3 pl-md-4"
        >
          <span class="d-block mb-2 stand-up-summary__description">
            Total No of Stand Up recorded
          </span>
          <strong class="stand-up-summary__count">{{ count }}</strong>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "StandUpSummary",
  props: {
    count: {
      type: Number,
      default: 0,
    },
    from: {
      type: String,
      default: "monthly",
    },
  },
  data() {
    return {
      filterText: {
        monthly: "Last 30 days",
        weekly: "Last 30 days",
        today: "Today",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.stand-up-summary {
  &__description {
    color: $grey-tint-2;
    font-size: 0.75rem;
  }

  &__count {
    font-size: 1.125rem;
  }
}
</style>
