<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="18"
    height="18"
    fill="#08569d"
    stroke="#08569d"
    viewBox="0 0 577.029 577.029"
  >
    <path
      d="M288.514 148.629c73.746 0 136.162 33.616 175.539 61.821 46.652 33.415 70.66 65.737 76.885 78.065-6.232 12.327-30.232 44.649-76.885 78.065-39.377 28.204-101.793 61.82-175.539 61.82-73.746 0-136.161-33.616-175.539-61.82-46.661-33.416-70.66-65.738-76.894-78.065 6.234-12.328 30.233-44.65 76.885-78.065 39.387-28.205 101.802-61.821 175.548-61.821m0-34.972C129.176 113.657 0 253.543 0 288.515s129.176 174.857 288.514 174.857c159.339 0 288.515-139.886 288.515-174.857S447.854 113.657 288.514 113.657zm0 69.944c-57.939 0-104.914 46.975-104.914 104.914 0 57.938 46.975 104.914 104.914 104.914s104.914-46.976 104.914-104.914c0-57.939-46.975-104.914-104.914-104.914zm-28.248 104.914c-24.515 0-44.388-19.873-44.388-44.388 0-24.515 19.873-44.387 44.388-44.387 24.515 0 44.388 19.873 44.388 44.387 0 24.515-19.873 44.388-44.388 44.388z"
    />
  </svg>
</template>
  <script>
export default {
  name: "EyeIcon",
};
</script>