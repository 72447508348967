<template>
  <div class="stand-up">
    <div class="dashboard-summary">
      <StandUpSummary :from="filterValue" :count="standUpCount" />
    </div>
    <div class="dashboard-content py-5">
      <div class="stand-up__actions d-flex justify-content-end">
        <div class="m-width-300 mr-2">
          <SearchBox
            v-model="searchStandup"
            bg-color="#D6DDE4"
            :rounded="5"
            placeholder="Search stand up by name, date, type..."
          />
        </div>
        <!-- <FilterDropdown variant="faint-blue" :filters="filters" @filtered="setFilter" /> -->
      </div>

      <div class="stand-up__body mt-3 pb-4">
        <b-table striped hover responsive :fields="fields" :items="filteredStandup">
          <template #cell(action)="data">
            <span @click="(id) => moreInfo(data.item.id)">
              <EyeIcon />
            </span>
          </template>
          <template #head(action)="data">
            <span class="d-none">{{ data.label }}</span>
          </template>
        </b-table>
        <div
          class="m-pagination overflow-auto d-flex p-4 justify-cotnent-between align-items-center"
        >
          <span class="flex-grow-1">
            Showing page {{ currentPage }} of {{ totalRows }}
          </span>
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            align="right"
            pills
          >
          </b-pagination>
        </div>
      </div>
    </div>
    <StandUpModal id="stand-up_modal" :standup="standup" />
  </div>
</template>
<script>
import StandUpSummary from "../../components/stand-up/StandUpSummary.vue";
import SearchBox from "../../components/SearchBox.vue";
// import FilterDropdown from "../../components/FilterDropdown.vue";
import EyeIcon from "../../components/svg/EyeIcon2.vue";
import StandUpModal from "../../components/stand-up/StandUpModal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "StandUp",
  components: {
    StandUpSummary,
    SearchBox,
    // FilterDropdown,
    EyeIcon,
    StandUpModal,
  },
  data() {
    return {
      // standUpCount: 30,
      searchStandup: '',
      filterValue: "weekly",
      perPage: 12,
      currentPage: 1,
      openedStandUp: {},
      filters: [
        {
          name: "Today",
          value: "today",
        },
        {
          name: "Weekly",
          value: "weekly",
        },
        {
          name: "Monthly",
          value: "monthly",
        },
      ],
      fields: [
        {
          key: "date",
          // sortable: true,
        },
        {
          key: "talent.name",
          label: "Name of Employee"
        },
        {
          key: "previous_task",
        },
        {
          key: "current_task",
        },
        {
          key: "blocker",
        },
        {
          key: "action",
        },
      ],
      items: [
        {
          date: "28 Oct, 2021",
          name_of_employee: "Will Baker",
          previous_day_task: "Srspiciatis undeatus ....",
          "today's_task": "Srspiciatis undeatus .... ",
          blocker: "Srspiciatis undeatus ....",
        },
        {
          date: "28 Oct, 2021",
          name_of_employee: "Will Baker",
          previous_day_task: "Srspiciatis undeatus ....",
          "today's_task": "Srspiciatis undeatus .... ",
          blocker: "Srspiciatis undeatus ....",
        },
        {
          date: "28 Oct, 2021",
          name_of_employee: "Will Baker",
          previous_day_task: "Srspiciatis undeatus ....",
          "today's_task": "Srspiciatis undeatus .... ",
          blocker: "Srspiciatis undeatus ....",
        },
        {
          date: "28 Oct, 2021",
          name_of_employee: "Will Baker",
          previous_day_task: "Srspiciatis undeatus ....",
          "today's_task": "Srspiciatis undeatus .... ",
          blocker: "Srspiciatis undeatus ....",
        },
        {
          date: "28 Oct, 2021",
          name_of_employee: "Will Baker",
          previous_day_task: "Srspiciatis undeatus ....",
          "today's_task": "Srspiciatis undeatus .... ",
          blocker: "Srspiciatis undeatus ....",
        },
        {
          date: "28 Oct, 2021",
          name_of_employee: "Will Baker",
          previous_day_task: "Srspiciatis undeatus ....",
          "today's_task": "Srspiciatis undeatus .... ",
          blocker: "Srspiciatis undeatus ....",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["standUp", "standup"]),
    totalRows() {
      return this.standUp.length;
    },
    standUpCount() {
      return this.standUp.length
      console.log(this.standUp)
    },
    filteredStandup() {
      return this.standUp.filter((standup) => {
        return standup.blocker.toLowerCase().match(this.searchStandup.toLowerCase()) ||
               standup.current_task.toLowerCase().match(this.searchStandup.toLowerCase()) ||
               standup.previous_task.toLowerCase().match(this.searchStandup.toLowerCase()) ||
               standup.date.match(this.searchStandup) ||
               standup.talent.name.toLowerCase().match(this.searchStandup.toLowerCase())
      })
    }
  },
  methods: {
    ...mapActions([
      "fetchStandUp",
      "getStandUp"
    ]),
    setFilter(value) {
      console.log(value);
    },
    moreInfo(id) {
      this.getStandUp(id)
      this.$bvModal.show("stand-up_modal");
      console.log("working");
      console.log(this.standUp)
      console.log(this.$store.standup)
    },
  },

  created() {
    this.fetchStandUp() 
  }
};
</script>
<style lang="scss" scoped>
.stand-up {
  &__body {
    background-color: $white;
    border-radius: $size-10;
  }
}
</style>
