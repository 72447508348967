<template>
  <div class="search-box position-relative">
    <SearchIcon class="search-box__icon position-absolute ml-3" />
    <b-form-input
      type="text"
      size="lg"
      class="search-box__input py-2 px-5"
      :class="inputClasses"
      :style="styleObject"
      :placeholder="placeholder"
      :value="value"
      @input="handleInput"
    >
    </b-form-input>
  </div>
</template>
<script>
import SearchIcon from "./svg/SearchIcon.vue";
export default {
  name: "SearchBox",
  components: {
    SearchIcon,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    rounded: {
      type: Number,
      default: 0,
    },
    bgColor: {
      type: String,
      default: "#ffffff",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  computed: {
    inputClasses() {
      return [
        "pl-5",
        "pr-2",
        this.rounded > 0 ? `m-rounded-${this.rounded}` : "rounded-0",
      ];
    },
    styleObject() {
      return {
        "background-color": `${this.bgColor} !important`,
      };
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-box {
  &__icon {
    top: 10px;
  }
  &__input {
    font-size: 0.75rem;
    height: 40px;
  }
}
</style>
